<template>
  <v-row class="justify-md-space-around justify-center align-center">
    <v-col class="d-none d-md-flex" cols="4">
      <v-img   src="../assets/home.svg"></v-img>
    </v-col>
    <v-col cols="8" xs="12" sm="10" md="6" >
      <p><span class="blue--text text--darken-4  display-3 font-weight-black">ODiM.</span>
     <span class="blue--text text--lighten-1 display-2 font-weight-bold">
        <br/>Analyser le discours pour mieux diagnostiquer</span></p><br/>
          <v-row class="justify-md-space-around align-end">
        <v-col>
        <!-- <v-btn big color="indigo" to="/signup" outlined>
          <v-icon dark>mdi-account</v-icon> Créer un compte
        </v-btn> -->
        <v-btn x-large class="mt-5" color="indigo" to="/login" rounded outlined>Accéder à l'outil
        </v-btn>
      </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Home',
};
</script>
